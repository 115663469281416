.blockMenu{
    display: flex;
    box-shadow: 0px 1px 5px 4px lightgrey;
    background-color: #52a38d;
    height: 50px;
    position: fixed;
    top: 0px;
    width: 100%;
    font-size: 0.7rem;
}

.itemMenu{
    color: white;
    text-align: center;
    padding: 20px;
    cursor: pointer;
}