.menuItem{
    color: #FFFFFF;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    padding: 15px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}

.menuItem a{
    color: #FFFFFF;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    text-decoration: none;
}

.menuItem label{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.menuItem svg{
    font-size: 20px;
    cursor: pointer;
    padding-right: 3px;
}

.operator{
    font-family: cursive;
    font-weight: normal;
}

.modalPDF{
    border: 1px #5ca48d solid;
    margin: 1%;
    position: absolute;
    top: 50px;
    width: 98%;
    box-shadow: 0px 0px 7px 2px lightgrey;
    border-radius: 3px;
}

.completar{
    height: 40px;
    width: 70%;
    border: 1px #5ca48d solid;
    float: right;
}

.printableArea {
    display: none;
}
@media print {
    * {
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
    }
   .no-printableArea {
       display: none;
    }
    .printableArea {
       display: block;
       padding: 0px!important;
       height: 100%; /* You must set a specified height */
        background-position: center; /* Center the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }

    .printableArea .fecha{
        margin: 30px 10px!important;
     }

    .printableArea .operation{
        padding: 25px!important;
     }
}